import Vue from "vue";
import Vuex from "vuex";
import localization from "./modules/localization";
import alert from "./modules/alert";
import preloader from "./modules/preloader";
import authentication from "./modules/authentication";
import navigation from "./modules/navigation";
import dialog from "./modules/dialog";
import user from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    localization,
    alert,
    authentication,
    navigation,
    preloader,
    dialog,
    user,
  },
  strict: process.env.NODE_ENV !== "production"
});
