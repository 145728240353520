import APIModel from "../APIModel";
export default class Orders extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "orders";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 500, 400, 422, 400)
      .get(`${this.resourceUrl}`, params);
  }

  async getOrdersCount(params: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 500, 400, 422, 400)
      .get(`${this.resourceUrl}/counts`, params);
  }

  async getLogs(id: string): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/logs`, {});
  }

  async getOrderStatuses(id: string): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/status_history`, {});
  }

  async getMarks(orders: Array<any>): Promise<any> {
    return await this.http.get(`orders_highlights/list`, { orders });
  }

  async getPreviewCert(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/order_document_print`);
  }

  async read(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .useFormData()
      .post(`orders_highlights/read?_method=PATCH`, payload);
  }

  async getMarksTest(): Promise<any> {
    return await this.http.get(`orders_highlights/test`, {});
  }

  async cloneOrder(id: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 404, 400, 403, 401)
      .post(`${this.resourceUrl}/${id}/replicate`, {});
  }

  async getLogsStatuses(): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/logs/statuses`, {});
  }

  async getForEdit(id: number, params: any = {}): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`, params);
  }

  async getExpCertificates(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(403, 404, 500)
      .get(`${this.resourceUrl}/${id}/cert_request`, {});
  }

  async printOrder(id: number, params: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(400, 500, 404, 403)
      .get(`${this.resourceUrl}/${id}/order_print`, params);
  }

  async printAaijOrder(id: number, params: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(400, 500, 404, 403)
      .get(`${this.resourceUrl}/${id}/order_document_print`, params);
  }

  async show(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/show`);
  }

  async getList(): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/list`, {});
  }

  async getPaymentData(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 500)
      .post(`mpay/test_payment`, payload);
  }

  async liveSearch(query: string): Promise<any> {
    //todo убрать эти параметры параметры, сделать нормально на бэке
    //todo фильтры указывать в компоненте где вызывается апи
    const params = {
      fields: {
        value: "id",
        text: "document_number"
      },
      route: "v1.orders.index.get",
      filter: {
        document_number: query
      }
    };
    return await this.http
      .ignoreErrorHandler(500, 422, 404)
      .get(`${this.resourceUrl}/live_search`, params);
  }

  async liveSearchClient(params: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 422, 404)
      .get(`clients/live_search_for_request`, params);
  }

  async getStatuses(): Promise<any> {
    return await this.http.get(`order_statuses/list`, {});
  }

  async getGuaranteeFile(id: number): Promise<any> {
    return await this.http.get(
      `${this.resourceUrl}/${id}/get_confirmation_file`
    );
  }

  async getPaymentMethods(): Promise<any> {
    return await this.http.get(`payment_methods/list`);
  }

  async getComments(id: number) {
    return await this.http.get(`/order_notes/${id}/show`);
  }

  async updateComment(id: number, payload: any) {
    return await this.http
      .ignoreErrorHandler(400, 404, 500, 422)
      .put(`/order_notes/${id}/update`, payload);
  }

  async issueOrder(order_id: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 422, 500)
      .patch(`${this.resourceUrl}/${order_id}/issue`, {});
  }

  /* To be changed */

  async changeOrderStatus(id: number, payload: any = {}) {
    return await this.http
      .ignoreErrorHandler(404, 422, 500)
      .post(`${this.resourceUrl}/${id}/change_status`, payload);
  }

  async getOrderReleaseModes(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 422, 500)
      .get(`${this.resourceUrl}/release_types`, {});
  }

  async getLanguages(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 422, 500)
      .get(`/languages`, {});
  }

  async getCardTypes(service_id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 422, 500)
      .get(`${this.resourceUrl}/${service_id}/carnet_types`, {});
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 500)
      .useFormData()
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async createExpertize(id: number, params: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 500, 404)
      .post(`${this.resourceUrl}/cert/${id}/create/exp`, params);
  }

  async getDocumentContent(id: number, params: object = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 500)
      .get(`${this.resourceUrl}/${id}/document_content`, params);
  }

  async saveDocumentContent(id: number, payload: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 500)
      .post(`${this.resourceUrl}/${id}/document_content`, payload);
  }

  async changeManager(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 500)
      .post(`${this.resourceUrl}/change_manager?_method=PATCH`, payload);
  }

  async changeTax(id: number, payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${id}/confirm_payment?_method=PATCH`, payload);
  }

  async addGuaranteeFile(id: number, payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(
        `${this.resourceUrl}/${id}/add_confirmation_file?_method=PATCH`,
        payload
      );
  }

  async create(payload: any, params: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 500)
      .useFormData()
      .setParams(params)
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }

  async deletePayment(id: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 403, 404)
      .delete(`order_payments`, { id });
  }

  async getAssistants(orderId: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 500, 422)
      .get(`order_experts/${orderId}/show`, {});
  }

  async getLastPowerOfAttorney(params: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 500, 422, 401, 403)
      .get(`${this.resourceUrl}/last_power_of_attorney`, params);
  }

  async updateAssistants(orderId: number, params: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 500, 422)
      .useFormData()
      .post(`order_experts/${orderId}/update`, params);
  }

  async downloadCertification(orderId: number, params: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 500, 422)
      .get(
        `${this.resourceUrl}/${orderId}/pdf/last_certificate_templates`,
        params
      );
  }

  async customLiveSearch(params: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 422, 404)
      .get(`${this.resourceUrl}/document_existing_live_search`, params);
  }

  async getScannedDocuments(id: number): Promise<any> {
    return await this.http.get(
      `${this.resourceUrl}/${id}/scanned_documents`,
      {}
    );
  }

  async getDocument(id: number): Promise<any> {
    return await this.http.get(
      `${this.resourceUrl}/certificate_templates/${id}`,
      {}
    );
  }

  async saveDocument(id: number, payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 422)
      .post(`${this.resourceUrl}/certificate_templates/${id}`, payload);
  }

  async sendScannedDocuments(id: number, scanned_documents: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .useFormData()
      .post(`${this.resourceUrl}/${id}/scanned_documents`, {
        scanned_documents
      });
  }
}
